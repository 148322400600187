const textStyles = {
  body: {
    fontSize: 'sm',
    color: 'grey.200',
  },
  'body-xs': {
    fontSize: 'xs',
    color: 'grey.200',
  },
};

export default textStyles;
