import { ChildrenProp } from '@helpers/ChildrenProp';
import { ChainProvider as CosmosKitChainProvider } from '@cosmos-kit/react';
import { assets, chains } from 'chain-registry';
import { wallets as keplrWallets } from '@cosmos-kit/keplr';
import { wallets as leapWallets } from '@cosmos-kit/leap';
import { wallets as xdefiWallets } from '@cosmos-kit/xdefi';
import { isMobile } from 'react-device-detect';
import {
  CHAINS,
  KUJIRA_MAINNET_RPC,
  KUJIRA_TESTNET_RPC,
  MAINNET_CHAINS,
  OSMOSIS_MAINNET_RPC,
  OSMOSIS_TESTNET_RPC,
} from 'src/constants';
import { ChainId } from '@models/ChainId';
import { getChainId, getGasPrice } from '@helpers/chains';
import { useEffect, useState } from 'react';
import { Keplr, Window as KeplrWindow } from '@keplr-wallet/types';
import { MainWalletBase } from '@cosmos-kit/core';
import { EventData, ExecutionSkippedReason } from 'src/interfaces/dca/response/get_events_by_resource_id';

declare global {
  interface Window extends KeplrWindow {
    xfi: { keplr: Keplr };
    leap: any;
    keplr: any;
  }
}

export function ChainProvider({ children }: ChildrenProp) {
  const [wallets, setWallets] = useState<MainWalletBase[]>([]);

  useEffect(() => {
    setWallets(
      [...(window.leap || isMobile ? leapWallets : []), ...keplrWallets, ...(window.xfi ? xdefiWallets : [])].filter(
        (wallet) => (isMobile ? !wallet.isModeExtension : wallet.isModeExtension),
      ),
    );
  }, []);

  const supportedChains = chains.filter((chain) =>
    (process.env.NEXT_PUBLIC_APP_ENV === 'production' ? MAINNET_CHAINS : CHAINS).includes(chain.chain_id as ChainId),
  );

  return wallets.length > 0 ? (
    <CosmosKitChainProvider
      chains={supportedChains}
      assetLists={assets}
      wallets={wallets}
      endpointOptions={{
        isLazy: true,
        endpoints: {
          kujira: {
            rpc: [KUJIRA_MAINNET_RPC],
          },
          osmosis: {
            rpc: [OSMOSIS_MAINNET_RPC],
          },
          ...(process.env.NEXT_PUBLIC_APP_ENV !== 'production'
            ? {
                kujiratestnet: {
                  rpc: [KUJIRA_TESTNET_RPC],
                },
                osmosistestnet: {
                  rpc: [OSMOSIS_TESTNET_RPC],
                },
                archwaytestnet: {},
              }
            : {}),
        },
      }}
      signerOptions={{
        signingCosmwasm: (chain) => ({
          gasPrice: getGasPrice(getChainId(typeof chain === 'string' ? chain : chain.chain_name) as ChainId),
        }),
      }}
      walletConnectOptions={{
        signClient: {
          projectId: '50fa0187387c8c2f72d360c6ba9f3333',
          relayUrl: 'wss://relay.walletconnect.org',
          metadata: {
            name: 'Calculated Finance',
            description: 'Calculated Finance',
            icons: [],
            url: 'https://app.calculated.fi',
          },
        },
      }}
    >
      {children}
    </CosmosKitChainProvider>
  ) : null;
}
